<template>
<div>
  <v-card height="400"></v-card>
</div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {}
  },
  created() {
    this.$store.state.bot_token = this.$route.params.token
  },
}
</script>

<style scoped>

</style>
